import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ['layout'];

	updateLayout(event) {
		event.preventDefault();
		const proportionClass = event.currentTarget.dataset.proportion; // e.g., 'proportion-1-1'

		// Remove existing proportion classes
		this.layoutTarget.classList.remove('layout--1-1', 'layout--3-1', 'layout--1-3');

		// Add the new proportion class
		this.layoutTarget.classList.add(proportionClass);
	}
}
