import { Controller } from '@hotwired/stimulus';
export const create = () => {
	return class extends Controller {
		static targets = ['link', 'spec', 'cap', 'back'];
		openCap(event) {
			event.preventDefault();

			this.specTarget.classList.add('is-close');
			this.capTargets.map((cap) => {
				cap.classList.add('is-open');
			});
			this.element.querySelector(event.currentTarget.getAttribute('href')).classList.add('is-open');
		}
		close(event) {
			event.preventDefault();

			this.specTarget.classList.remove('is-close');
			event.currentTarget.closest('.dropdown-cap').classList.remove('is-open');
		}

		closeAll(event) {
			event.preventDefault();

			this.specTarget.classList.remove('is-close');
			event.currentTarget.closest('.dropdown-cap').classList.remove('is-open');
			event.currentTarget.closest('.dropdown-menu').classList.remove('show');
		}
	};
};
