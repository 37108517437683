import { Controller } from '@hotwired/stimulus';

export const create = () => {
	return class extends Controller {
		static targets = ['link'];

		addClass(event) {
			event.preventDefault();

			this.linkTargets.map((link) => {
				link.classList.remove('is-active');
			});

			event.currentTarget.classList.add('is-active');
		}
	};
};
