import { Controller } from '@hotwired/stimulus';

export const create = () => {
	return class extends Controller {
		static targets = ['link', 'content'];

		changeTab(event) {
			event.preventDefault();
			const selectedLink = event.currentTarget;
			const contentId = selectedLink.getAttribute('href');
			const activeContent = this.element.querySelector(contentId);

			if (!activeContent) return;

			this.linkTargets.forEach((link) => {
				link.classList.remove('is-active');
			});
			selectedLink.classList.add('is-active');

			this.contentTargets.forEach((content) => {
				content.classList.remove('is-active');
			});
			activeContent.classList.add('is-active');
		}
	};
};
