import { Controller } from '@hotwired/stimulus';

export const create = () => {
	return class extends Controller {
		toggle = (e) => {
			e.preventDefault();

			const trigger = e.currentTarget;
			const content = trigger.closest('[data-toggle-class-content]');
			const cName = trigger.getAttribute('data-toggle-class');
			const cContent = trigger.getAttribute('data-toggle-class-content');
			const focusInput = trigger.getAttribute('data-focus-input');

			if (cContent && document.querySelectorAll(cContent).length > 0) {
				e.preventDefault();
			}

			if (!content || (cContent && document.querySelectorAll(cContent).length > 0)) {
				trigger.classList.toggle(cName);
			}

			if (content && content != trigger) {
				content.classList.trigger(cName);
			}

			if (cContent) {
				document.querySelectorAll(cContent).forEach((element) => {
					element.classList.toggle(cName);

					if (element.querySelector(focusInput)) {
						setTimeout(() => {
							element.querySelector(focusInput).focus();
						}, 100);
					}
				});
			}
		};
	};
};
