export function saveThemeSettings(data) {
	const url = '/settings/layout/save'; // URL endpointu

	return fetch(url, {
		method: 'POST', // Metoda POST
		headers: {
			'Content-Type': 'application/json', // Hlavička pro JSON
			'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') // CSRF token
		},
		body: JSON.stringify(data) // Tělo requestu jako JSON string
	})
		.then(response => {
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			return response.json();
		})
		.catch(error => {
			console.error('Error fetching data:', error);
			throw error;
		});
}
