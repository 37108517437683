import { Controller } from '@hotwired/stimulus';
import { saveThemeSettings } from './saveThemeSettings';

export const create = () => {
	return class extends Controller {
		static targets = ['input'];

		toggleTheme(event) {
			if (event.currentTarget.checked) {
				document.documentElement.setAttribute('data-bs-theme', 'dark');
			} else {
				document.documentElement.removeAttribute('data-bs-theme');
			}

			const data = {
				key: 'darkMode',
				value: event.currentTarget.checked
			};

			saveThemeSettings(data);
		}

		toggleVerseNums(event) {
			const listNums = document.querySelectorAll('.list__num');
			listNums.forEach(element => {
				element.style.display = event.currentTarget.checked ? 'none' : 'flex';
			});

			const data = {
				key: 'hideListNums',
				value: event.currentTarget.checked
			};

			this.saveThemeSettings(data);
		}

		toggleTextNotes(event) {
			const textNotes = document.querySelectorAll('sup.odkaz');
			textNotes.forEach(element => {
				element.style.display = event.currentTarget.checked ? 'none' : 'inline';
			});

			const data = {
				key: 'hideTextNotes',
				value: event.currentTarget.checked
			};

			this.saveThemeSettings(data);
		}

		saveThemeSettings(data) {
			const url = '/settings/layout/save'; // URL endpointu

			fetch(url, {
				method: 'POST', // Metoda POST
				headers: {
					'Content-Type': 'application/json', // Hlavička pro JSON
					'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content') // CSRF token
				},
				body: JSON.stringify(data) // Tělo requestu jako JSON string
			})
				.then(response => {
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then(data => {
					this.outputTarget.textContent = JSON.stringify(data, null, 2);
				})
				.catch(error => {
					throw error;
				});
		}
	};
};
