import { Controller } from '@hotwired/stimulus';
import { MQ } from '../tools/MQ';

export const create = (options) => {
	return class extends Controller {
		static targets = ['menu', 'wrap'];

		connect() {
			window.addEventListener('resize', () => {
				this.toggle.bind(this);
			});
		}

		menuShowed = false;

		toggle(event) {
			event.preventDefault();

			const menu = this.menuTarget;
			menu.style.left = '';

			// Remove the dropdown menu from its current position
			this.menuTarget.remove();

			this.menuShowed = !this.menuShowed;
			menu.classList.toggle('show', this.menuShowed);

			// Append the dropdown menu to the body
			if (this.menuShowed) {
				menu.style.position = 'absolute';
				menu.style.display = 'block';
				menu.style.zIndex = '10000';
				this.element.appendChild(menu);
			} else {
				menu.style.display = 'none';
				this.wrapTarget.appendChild(menu);
			}	
			if (MQ('lgUp')) {
				this.updateCorners();
			}
		}

		close(event) {
			this.toggle(event);
		}

		updateCorners() {
			const wrapCorners = this.getElementCorners(this.wrapTarget);
			const menuCorners = this.getElementCorners(this.menuTarget);
			const difference = menuCorners.rightBottom.x - (Math.min(wrapCorners.rightBottom.x, window.innerWidth));
			this.menuTarget.style.left = `${-difference}px`;
		}

		getElementCorners(element) {
            // Get the bounding rectangle of the element
            const rect = element.getBoundingClientRect();

            // Calculate the scroll positions to adjust for scrolling
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

            // Calculate the right bottom and left bottom positions
            const rightBottomX = rect.right + scrollLeft;
            const rightBottomY = rect.bottom + scrollTop;
            const leftBottomX = rect.left + scrollLeft;
            const leftBottomY = rect.bottom + scrollTop;

            return {
                rightBottom: { x: rightBottomX, y: rightBottomY },
                leftBottom: { x: leftBottomX, y: leftBottomY }
            };
        };
	};
};
