import { Controller } from "@hotwired/stimulus";
import { saveThemeSettings } from './saveThemeSettings';

export default class extends Controller {
	connect() {
		// Inicializace touchY s výchozí hodnotou
		this.touchY = null;

		// Přidání event listenerů pro touchmove, touchend a click
		this.element.addEventListener('touchmove', this.updateHeight.bind(this));
		this.element.addEventListener('touchend', this.handleTouchEnd.bind(this));

		const mapNavigationElements = document.querySelectorAll('.list .map-navigation');
		if (mapNavigationElements) {
			// Zavolání setPanelHeight(true) při prvním načtení
			this.setPanelHeight(true);

			// Přidání listeneru pro click na všechny prvky .map-navigation
			mapNavigationElements.forEach((element) => {
				element.addEventListener('click', this.handleMapNavigationClick.bind(this));
			});
		}

		// Přidání listeneru pro vlastní event
		this.element.addEventListener('swipeArea:resized', this.handleSwipeAreaResized.bind(this));
	}

	disconnect() {
		// Odebrání event listenerů při odpojení controlleru
		this.element.removeEventListener('touchmove', this.updateHeight.bind(this));
		this.element.removeEventListener('touchend', this.handleTouchEnd.bind(this));

		const mapNavigationElements = document.querySelectorAll('.list .map-navigation');
		if (mapNavigationElements) {
			mapNavigationElements.forEach((element) => {
				element.removeEventListener('click', this.handleMapNavigationClick.bind(this));
			});
		}

		this.element.removeEventListener('swipeArea:resized', this.handleSwipeAreaResized.bind(this));
	}

	handleMapNavigationClick(event) {
		// Volání setPanelHeight s false při každém kliknutí
		this.setPanelHeight(false);
	}

	updateHeight(event) {
		event.preventDefault();
		this.touchY = event.touches[0].clientY; // Uložení Y pozice dotyku
		const limitedHeight = this.calculateLimitedHeight();

		const layoutSide = this.element.closest('.layout__side');
		if (!layoutSide) return;

		layoutSide.style.height = `${limitedHeight}vh`;

		// Přidání nebo odebrání třídy 'is-full' na základě výšky
		if (limitedHeight === 100) {
			layoutSide.classList.add('is-full');
		} else {
			layoutSide.classList.remove('is-full');
		}
	}

	handleTouchEnd(event) {
		// Vyvolání vlastního eventu pouze při ukončení dotyku
		this.element.dispatchEvent(new CustomEvent('swipeArea:resized'));
	}

	handleSwipeAreaResized(event) {
		// Volání vlastní akce nebo aktualizace UI
		this.yourCustomAction();
	}

	yourCustomAction(panelHeightVh = null) {
		// Pokud se nejedná o mobilní zobrazení, nepokračuje v počítání rozměrů mapy
		if (window.innerWidth > 991)
			return;

		let calcMapHeight;
		if (panelHeightVh !== null) {
			// Použití pevné výšky panelu, pokud je poskytnuta
			calcMapHeight = this.calculateMapHeight(panelHeightVh);

		} else {
			if (this.touchY === null) {
				console.error('touchY is not defined');
				return;
			}
			// Výpočet výšky mapy na základě touchY
			calcMapHeight = this.calculateMapHeight();
		}

		const mapElement = document.getElementById('map');
		mapElement.style.height = `${calcMapHeight}vh`;
		mapElement.style.maxHeight = `${calcMapHeight}vh`;

		if (window.map) {
			setTimeout(() => {
				window.map.invalidateSize();
			}, 10); // Nastavte timeout dle potřeby
		} else {
			console.error('Global map variable is not defined');
		}

		panelHeightVh = this.calculateLimitedHeight();
		console.log(panelHeightVh);
		this.savePanelHeight(panelHeightVh);
	}

	setPanelHeight(savedPosition = false) {
		if(!window.isMobile()){
			return;
		}

		const layoutSide = this.element.closest('.layout__side');
		let panelHeight = 40;

		if (savedPosition) {
			panelHeight = parseFloat(layoutSide.style.height);
		}

		layoutSide.style.height = panelHeight + 'vh';
		this.yourCustomAction(panelHeight);
		this.savePanelHeight(panelHeight);
	}

	savePanelHeight(panelHeight) {
		const data = {
			key: 'panelHeight',
			value: `${panelHeight}vh`,
		};

		saveThemeSettings(data)
			.then((responseData) => {
				console.log(JSON.stringify(responseData, null, 2));
			})
			.catch((error) => {
				console.error(`Error: ${error.message}`);
			});
	}

	calculateLimitedHeight() {
		const viewportHeight = window.innerHeight;
		const newHeight = viewportHeight - this.touchY;
		const heightInVh = (newHeight / viewportHeight) * 100;
		return Math.min(heightInVh, 100);
	}

	calculateMapHeight(panelHeightVh = null) {
		const viewportHeight = window.innerHeight;

		// Získání výšky elementů s třídami 'b-bookmarks' a 'header'
		const bookmarksHeight = document.querySelector('.b-bookmarks')?.offsetHeight || 0;
		const headerHeight = document.querySelector('header')?.offsetHeight || 0;

		// Převod výšky těchto dvou elementů na vh jednotky
		const bookmarksHeightVh = (bookmarksHeight / viewportHeight) * 100;
		const headerHeightVh = (headerHeight / viewportHeight) * 100;

		// Vypočítání omezené výšky na základě dotyku
		const limitedHeight = panelHeightVh !== null ? panelHeightVh : this.calculateLimitedHeight();

		// Odečtení výšky těchto dvou elementů od celkové výšky
		return 100 - limitedHeight - bookmarksHeightVh - headerHeightVh + 2;
	}
}
