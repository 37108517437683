import { Application } from '@hotwired/stimulus';

window.App = {
	run(options) {
		// Load controllers
		const context = require.context('./controllers', false, /\.(js|ts)$/);
		let controllers = context
			.keys()
			.map((key) => {
				const identifier = (key.match(/^(?:\.\/)?(.+)(?:\..+?)$/) || [])[1];
				const module = context(key);
				// export from module create method if you need pass options
				const controllerConstructor = module?.create?.(options) || module?.default;

				if (identifier && typeof controllerConstructor === 'function') {
					return { identifier, controllerConstructor };
				}
			})
			.filter((value) => value);

		// list of controllers
		// console.log(controllers);

		// you can filter only some by name
		// controllers = controllers.filter(({ identifier }) => ['test'].includes(identifier));

		// Start aplication on next frame to control FID
		requestAnimationFrame(() => {
			const application = Application.start();
			application.load(controllers);
		});
	},
};
