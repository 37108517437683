import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";

export default class extends Controller {
	connect() {
		// Ujistěte se, že element existuje před vytvořením tooltipu
		if (this.element) {
			this.tooltip = new Tooltip(this.element, {
				title: () => this.element.getAttribute("data-bs-title"),
				placement: this.element.getAttribute("data-bs-placement") || 'top',
				trigger: 'hover focus'
			});
		}
	}

	disconnect() {
		// Ujistěte se, že tooltip a element existují před jejich odstraněním
		if (this.tooltip) {
			try {
				if (document.body.contains(this.element)) {
					this.tooltip.dispose();
				}
			} catch (error) {
				console.error('Error disposing tooltip:', error);
			}
		}
	}
}
